
import { mapGetters, mapActions } from 'vuex'
import Cart from '~/apollo/queries/cart'

export default {
  name: 'Search',
  components: {
    userIcon: () => import('@/assets/icons/user_icon.svg'),
    userIconBf: () => import('@/assets/icons/user_icon-white.svg'),
    cartIcon: () => import('@/assets/icons/cart_icon.svg'),
    cartIconBf: () => import('@/assets/icons/cart_icon_white.svg'),
    panelUser: () => import('@/components/Header/PanelUser'),
    itensCart: () => import('@/components/Header/VAction-itens-cart'),
    itensCartNew: () => import('@/components/Header/VAction-itens-cart-new')
  },
  data: () => ({
    hoverCart: false,
    hoverUser: false,
    showNotification: false,
    loadingCart: true,
    cartQty: 0
  }),
  props: {
    bgColors: {
      type: String,
      default: '#000000'
    },
    txtColors: {
      type: String,
      default: '#FFFFFF'
    }
  },
  mounted() {
    this.ShowLoadingCart()
    setTimeout(() => {
      this.loadingCart = false
    }, 1900)
  },
  computed: {
    ...mapGetters({
      loadingpublic: 'layout/loadingpublic',
      GGcartQTY: 'cart/cartQTY'
    }),
    cssProps() {
      return {
        '--bg-color': this.bgColors,
        '--txt-color': this.txtColors
      }
    }
  },
  watch: {
    loadingpublic: 'ShowActionCart',
    GGcartQTY: 'refleshQTY',
    showNotification: 'ChangeNotification'
  },
  methods: {
    closedCart() {
        this.hoverCart = false
    },
    refleshQTY() {
      window.scrollTo(0, 0)
      this.cartQty = this.GGcartQTY
    },
    ShowActionCart() {
      this.loadingCart = this.loadingpublic
      if (this.loadingpublic === false) {
        this.showNotification = true
      }
      if (this.loadingpublic === null) {
        this.showNotification = false
      }
    },
    ChangeNotification() {
      setTimeout(() => {
        this.showNotification = false
        this.OpenResume = true
        this.closeMobile = false
        this.hoverCart = true
      }, 2000)
    },
    async ShowLoadingCart() {
      if (localStorage.current_cart_token && !localStorage.current_customer_token) {
        await this.$apollo.query({
          query: Cart,
          variables: {
            cart_id: localStorage.current_cart_token
          }
        }).then((data) => {
          this.cartQty = data.data.cart.total_quantity
          this.loadingCart = false
        }).catch(({ graphQLErrors }) => {
        })
      }
      if (localStorage.current_cart_token && localStorage.current_customer_token) {
        await this.$apollo.query({
          query: Cart,
          variables: {
            cart_id: localStorage.current_cart_token
          },
          context: {
            headers: {
              authorization: `bearer ${localStorage.current_customer_token}`
            }
          }
        }).then((data) => {
          this.cartQty = data.data.cart.total_quantity
          this.loadingCart = false
        }).catch(({ graphQLErrors }) => {
        })
      }
    }
  }
}
